<template>
<!-- eslint-disable max-len -->
  <div class="col bg-light access-requests-page pr-4 pl-3">
    <div class="row no-gutters pt-3">
      <div class="col-4">
        <multiselect
          placeholder="Filter by status"
          label="name" track-by="value"
          v-model="filterState"
          :options="states"
          :searchable="false"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="true"
          :preselect-first="false"
          :disabled="isLoadingAccessRequests"
          >
        </multiselect>
      </div>
    </div>

    <div class="row no-gutters pt-3">
      <div class="col">
        <!-- Modal -->
        <div class="modal fade" id="approvalModal" tabindex="-1" role="dialog" aria-labelledby="approvalModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="approvalModalLabel">
                  Access Request
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-group row">
                    <label for="phone" class="col-sm-4 col-form-label">Phone</label>
                    <div class="col-sm-8">
                      <input type="text" readonly disabled class="form-control-plaintext" id="phone" :value="currentAccessRequest.phone">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="firstName" class="col-sm-4 col-form-label">First Name</label>
                    <div class="col-sm-8">
                      <input type="text" readonly class="form-control-plaintext" id="firstName" :value="currentAccessRequest.first_name">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="lastName" class="col-sm-4 col-form-label">Last Name</label>
                    <div class="col-sm-8">
                      <input type="text" readonly class="form-control-plaintext" id="lastName" :value="currentAccessRequest.last_name">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="company" class="col-sm-4 col-form-label">Company</label>
                    <div class="col-sm-8">
                      <input type="text" readonly class="form-control-plaintext" id="company" :value="currentAccessRequest.company">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="email" class="col-sm-4 col-form-label">Email</label>
                    <div class="col-sm-8">
                      <input type="text" readonly class="form-control-plaintext" id="email" :value="currentAccessRequest.email">
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-approve"
                  @click="actionProcessAccessRequest(true)"
                  v-if="showApproveButton(currentAccessRequest.state)"
                >
                  Approve
                </button>
                <button type="button" class="btn btn-primary btn-reject btn-outline-light"
                  @click="actionProcessAccessRequest(false)"
                  v-if="showRejectButton(currentAccessRequest.state)"
                >
                  Reject
                </button>
                <button type="button" class="btn btn-light btn-dismiss btn-outline-dark" data-dismiss="modal">
                  Dismiss
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Table -->
        <div class="wrapper table-responsive bg-white pb-3">
          <table class="table table-hover table-borderless pl-3">
            <thead>
              <tr class="border-bottom">
                <th scope="col" class="pl-4">
                  Phone
                </th>
                <th scope="col">
                  Date Requested
                </th>
                <th scope="col">
                  Name
                </th>
                <th scope="col">
                  Company
                </th>
                <th scope="col">
                  Email
                </th>
                <th scope="col">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="isLoadingAccessRequests">
                <tr class="border-bottom">
                  <td class="text-center" colspan="6">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <template v-for="item in items">
                  <tr
                    :key="item.uuid"
                    class="border-bottom"
                    @click="actionOpenApprovalDialog(item)"
                  >
                    <td class="text-primary text-muted pl-4 pointer-on-hover underline-on-hover">
                      {{ item.phone }}
                    </td>
                    <td class="text-secondary">
                      {{ parseCreateDate(item.created) }}
                    </td>
                    <td class="text-secondary">
                      {{ item.first_name }} {{ item.last_name }}
                    </td>
                    <td class="text-secondary">
                      {{ item.company }}
                    </td>
                    <td class="text-secondary">
                      {{ item.email }}
                    </td>
                    <td class="text-secondary">
                      {{ item.state }}
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as $ from 'jquery';
import Utils from '@/utils';
import notify from '@/notifications';

import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
  },

  data() {
    return {
      page: 1,
      pageSize: 20,
      items: [],
      currentAccessRequest: {},
      approvalSuccessMessage: 'Done',
      filterState: null,
      states: [
        {
          name: 'Draft',
          value: 'draft',
        },
        {
          name: 'Rejected',
          value: 'rejected',
        },
        {
          name: 'Approved',
          value: 'approved',
        },
      ],
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getItems();
    });
  },

  beforeRouteUpdate(to, from, next) {
    this.getItems();
    next();
  },

  computed: {
    isLoadingAccessRequests() {
      return this.$store.getters['accessRequests/isLoading']('items');
    },
  },

  watch: {
    filterState(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getItems();
      }
    },
  },

  methods: {
    getItems(page, pageSize) {
      let loadPage = page;
      let loadPageSize = pageSize;
      if (!loadPage) {
        loadPage = this.page;
      }
      if (!loadPageSize) {
        loadPageSize = this.pageSize;
      }

      const state = this.filterState != null ? this.filterState.value : 'draft';

      return this.$store.dispatch(
        'accessRequests/getAccessRequests',
        {
          page: loadPage,
          pageSize: loadPageSize,
          state,
        },
      )
        .then((response) => {
          const { success, data } = response;
          if (success) {
            this.items = data.objects;
          }
          return response;
        });
    },

    parseCreateDate(dateString) {
      return Utils.parseISOString(dateString);
    },

    actionOpenApprovalDialog(item) {
      console.debug(`opening dialog for ${item.uuid}`);
      this.currentAccessRequest = item;
      $('#approvalModal').modal('show');
    },

    actionProcessAccessRequest(approve) {
      console.debug(`request approve: ${approve}`);
      const requestId = this.currentAccessRequest.uuid;
      this.$store.dispatch(
        'accessRequests/approveAccessRequest',
        {
          requestId,
          isApproved: approve,
        },
      )
        .then((response) => {
          if (response.success) {
            notify({
              type: 'success',
              message: this.approvalSuccessMessage,
            });
            $('#approvalModal').modal('hide');
            this.getItems();
          } else {
            notify({
              type: 'error',
              message: 'An error occurred',
            });
          }
        });
    },

    showApproveButton(state) {
      return state !== 'approved';
    },

    showRejectButton(state) {
      return ['rejected', 'approved'].indexOf(state) < 0;
    },
  },
};
</script>

<style lang="scss">
.access-requests-page {
  .wrapper {
    overflow: auto;
    border-radius: 30px !important;
  }

  table {
    thead, tr, td {
      line-height: 3.5rem;
    }

    thead {
      font-size: 1.05rem;
    }
  }

  .btn-approve {
    background-color: #59B655 !important;
  }

  .btn-dismiss {
    background-color: white !important;
    color: grey !important;
  }
}
</style>
